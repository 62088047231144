/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import Iframe from "../components/iframe"
import Social from "../components/social"

const IndexPage = ({ data }) => (
  <Layout title={false} newsletter={true}>
    <SEO
      title="Home Page"
      description="Sito Ufficiale di Arturo Lorenzoni, Candidato Presidente alla Regione Veneto"
    />
    {/* <div sx={{ bg: `primary`, py: 90 }}>
      <Img
        sx={{ margin: [null, `auto`], maxWidth: 600 }}
        fluid={data.arturo.childImageSharp.fluid}
        alt="Arturo Lorenzoni Il Presidente"
      />
    </div> */}
    <Iframe
      url={`https://www.youtube-nocookie.com/embed/cdo9oYnKHgE?cc_load_policy=1`}
    >
      <h1 sx={{ textTransform: `uppercase`, my: 3, fontSize: [40, 50] }}>
        Segui <span sx={{ color: `secondary` }}>Arturo</span>
      </h1>
      <Social />
    </Iframe>
    <Box sx={{ bg: `primary`, my: 4, p: 5, textAlign: `center`, fontSize: 5 }}>
      <Link
        sx={{
          textTransform: `uppercase`,
          fontWeight: 600,
          color: `white`,
        }}
        to="/news/lettera-ai-veneti/"
      >
        Lettera ai Veneti
      </Link>
    </Box>
    <Container>
      <div
        sx={{
          m: [2, 4],
          fontSize: [`18px`, `22px`],
          textTransform: `uppercase`,
          borderLeftStyle: `solid`,
          borderLeftColor: `primary`,
          borderLeftWidth: `8px `,
          paddingLeft: `20px`,
          paddingY: [`5px`, `15px`],
        }}
      >
        <p>
          Insieme a me hanno deciso di mettersi in gioco tante persone, per
          condividere le proprie competenze, la propria storia e le proprie
          energie.
        </p>
        <p>Per andare oltre.</p>
        <p sx={{ fontStyle: `italic`, fontWeight: 800 }}>Arturo Lorenzoni</p>
      </div>
    </Container>
  </Layout>
)

export default IndexPage

export const data = graphql`
  query {
    arturo: file(relativePath: { eq: "lorenzoni-presidente.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
