/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const Iframe = ({ url, children }) => (
  <div
    sx={{
      display: `block`,
      m: [`20px 20px 0px 20px`, `40px 40px 0px 40px`],
      textAlign: `center`,
    }}
  >
    <Box
      sx={{
        position: `relative`,
        pt: [`75%`, `40%`],
      }}
    >
      <iframe
        sx={{
          position: `absolute`,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: `100%`,
          height: `100%`,
          borderRadius: `15px`,
        }}
        title="aboutVideo"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Box>
    {children}
  </div>
)

export default Iframe
